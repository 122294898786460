














































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

@Component({
  metaInfo: {
    title: "Kullanım Şartları"
  },
  components: {
    PageHeader,
    Breadcrumbs
  }
})
export default class TermsOfUse extends Vue {
  breadcrumbs = [
    {
      text: "Ana Sayfa",
      exact: true,
      to: { name: "Home" }
    },
    {
      text: "Kullanım Şartları"
    }
  ];
}
